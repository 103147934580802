<template>
  <div class="student-container">
    <div class="left-one-tab">
      <div class="head-title">
        <i class="iconfont">&#xe656;</i>
        <h2 class="title">电子商务直播理实一体化实训系统</h2>
      </div>
      <count-down class="count-down"></count-down>
    </div>
    <div class="right-box">
      <div class="two-tab-box">
        <div class="content">
          <el-scrollbar style="height: 100%">
            <router-view />
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountDown from "components/CountDown";
import { Logout } from "@/utils/apis";

export default {
  name: "StudentLayoutNobar",
  data() {
    return {
      role: "",
      name: localStorage.getItem('nickname') || null,
      avatar: require("../../assets/image/blank_headPic.png"),
    };
  },
  computed: {
  },
  components: {
    CountDown,
  },
  methods: {
    async logout() {
      const res = await Logout();
      localStorage.clear();
      this.$message.success(res.msg);
      this.$router.push("/");
    },
  },
  created() {
    this.role = localStorage.getItem("role");
    let avatar = localStorage.getItem("studentAvatar");
    this.avatar = avatar ? avatar : require("../../assets/image/blank_headPic.png")
  },
};
</script>

<style scoped lang="scss">
$blurColor: #a4a4af;
* {
  padding: 0;
  margin: 0;
}

.student-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #0c0e3f;
  overflow: hidden;
  .left-one-tab {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    .head-title {
      display: flex;
      align-items: center;
      margin-top: 10px;
      color: #edf1fd;
      .iconfont {
        margin: 0 10px;
        color: #4b45ff;
        font-size: 30px;
      }
      .title {
        font-weight: 400;
        font-size: 20px;
      }
    }
  }
  .right-box {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex: 1;
    background-color: #070932;
    overflow: hidden;
    .embed-page {
      box-sizing: border-box;
      width: 619px;
    }
    .two-tab-box {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      .head-two-tab {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 70px;
        background-color: #0c0e3f;
        overflow: hidden;
        .two-tab-list {
          box-sizing: border-box;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          height: 70px;
          background-color: #0c0e3f;
          .two-tab-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 144px;
            height: 50px;
            color: $blurColor;
            font-size: 18px;
            border-radius: 20px 20px 0 0;
          }
        }
      }
      .content {
        box-sizing: border-box;
        flex: 1;
        overflow: hidden;
        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
          .el-scrollbar__view {
            height: 100%;
          }
        }
      }
    }
    .count-down {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
.one-level-active {
  color: #fff !important;
  background-color: #262963;
}
.tow-level-active {
  position: relative;
  color: #fff !important;
  background-color: #070932;
  .two-text {
    z-index: 99;
  }
  .wider-box {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 184px;
    height: 20px;
    background-color: #070932;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      background-color: #0c0e3f;
      z-index: 9;
      border-radius: 0 0 20px 0;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      background-color: #0c0e3f;
      z-index: 9;
      border-radius: 0 0 0 20px;
    }
  }
}
.wider {
  width: 160px;
}
</style>
